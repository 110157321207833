import React from "react";
import { GitTriggersFeatureToggleVisibility } from "~/areas/projects/components/Triggers/useIsGitTriggersEnabled";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Note } from "~/components/form";
import imageForDeploymentTarget from "../../Images/Trigger-Deployment-Target.svg";
import imageForScheduledDeployment from "../../Images/Trigger-Scheduled-Deployment.svg";
import { OverviewContainer, OverviewHighlightText, TermsText } from "./CommonViews";
export const CommonProjectTriggerOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="ProjectTriggers">Triggers</ExternalLink> allow you to automate your deployments by responding to events such as changes in your deployment targets or time-based schedules.
                </p>
                <p>Choose from a subset of events that can occur in Octopus Deploy, apply a filter to those events, and decide on an action you want performed once the trigger fires.</p>
            </OverviewHighlightText>
            {isFeatureToggleEnabled("ExternalReleaseTriggerFeatureToggle") && (<TermsText>
                    <Note heading={"External feed triggers"}>allow you to specify container images or Helm charts that will automatically trigger a release of your project.</Note>
                </TermsText>)}
            {isFeatureToggleEnabled("ExternalReleaseTriggerFeatureToggle") && (<TermsText>
                    <Note heading={"Built-in package repository triggers"}>
                        allow you to specify which package updates from the built-in package repository will automatically trigger a release of your project.(formerly known as Automatic Release Creation)
                    </Note>
                </TermsText>)}
            <TermsText>
                <Note heading={"Deployment target triggers"}>allow you to specify which events on your deployment targets will automatically trigger a release of your project.</Note>
                <ImageWithPlaceholder src={imageForDeploymentTarget} alt={"Deployment process"}/>
            </TermsText>
            <TermsText>
                <Note heading={"Scheduled deployment triggers"}>allow you to define a recurring time-based schedule to automate deploying releases for your project.</Note>
                <ImageWithPlaceholder src={imageForScheduledDeployment} alt={"Deployment process"}/>
            </TermsText>
            <GitTriggersFeatureToggleVisibility>
                <TermsText>
                    <Note heading={"Git repository triggers"}>allow you to specify Git repositories to be monitored for new commits that will automatically trigger a release of your project.</Note>
                </TermsText>
            </GitTriggersFeatureToggleVisibility>
        </OverviewContainer>);
};
