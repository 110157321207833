import * as chameleon from "@chamaeleonidae/chmln";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import { logger } from "@octopusdeploy/logging";
import { useEffect } from "react";
import { useParams } from "react-router";
import { repository, session } from "~/clientInstance";
type RouteParamType = {
    [paramKey: string]: string;
};
export type ChameleonContext = {
    routeParams: RouteParamType;
};
export class InAppMessaging {
    static instance: InAppMessaging;
    public static async create(): Promise<InAppMessaging> {
        if (this.instance)
            return this.instance;
        this.instance = new InAppMessaging();
        await setupInAppMessaging();
        setupDesignTokens();
        return this.instance;
    }
    clear() {
        if (!window.chmln)
            return;
        window.chmln.clear();
        clearDesignTokens();
    }
}
export function useSetChameleonRouteParamsEffect() {
    const routeParams = useParams();
    useEffect(() => {
        const currentContext = window.chmlnContext;
        window.chmlnContext = { ...currentContext, routeParams };
        return () => {
            window.chmlnContext = { ...currentContext, routeParams: {} };
        };
    }, [routeParams]);
}
async function setupInAppMessaging() {
    const CHAMELEON_ACCOUNT = "S5MXxSUXSwK14Z8keWBBd6Fq3w6UFIsisQh74967mTyL9P-1RWXG9-F6slQQ10ZwKLtXEa";
    const CHAMELEON_FAST_URL = "https://experiences.octopus.com/";
    const userPropertiesBffResponse = await repository.InAppMessaging.getUserProperties();
    const featureTogglesFromSession = session.featureToggles ?? [];
    const currentVersion = userPropertiesBffResponse.Version;
    const currentVersionSplit = splitVersionNumber(currentVersion);
    const startVersion = userPropertiesBffResponse.StartVersion;
    const startVersionSplit = startVersion ? splitVersionNumber(startVersion) : [];
    const userProperties = {
        email: userPropertiesBffResponse.EmailAddress,
        emailAddress: userPropertiesBffResponse.EmailAddress,
        created: userPropertiesBffResponse.Created,
        currentVersion: currentVersion,
        currentVersionMajor: currentVersionSplit[0],
        currentVersionMinor: currentVersionSplit[1],
        currentVersionPatch: currentVersionSplit[2],
        startVersion: startVersion,
        startVersionMajor: startVersionSplit[0],
        startVersionMinor: startVersionSplit[1],
        startVersionPatch: startVersionSplit[2],
        installationId: userPropertiesBffResponse.InstallationId,
        enabledFeatureToggles: featureTogglesFromSession.filter((x) => x.IsEnabled).map((x) => x.Name),
        ...(userPropertiesBffResponse.LicenseSerialNumberHash && {
            company: {
                uid: userPropertiesBffResponse.LicenseSerialNumberHash,
            },
        }),
    };
    try {
        chameleon.init(CHAMELEON_ACCOUNT, { fastUrl: CHAMELEON_FAST_URL });
        chameleon.identify(userPropertiesBffResponse.UniqueId, userProperties);
        logger.info("Identified user for in-app messaging with {uid}.", { email: userPropertiesBffResponse.EmailAddress, uid: userPropertiesBffResponse.UniqueId });
    }
    catch (e) {
        logger.error("Failed to identify user for in-app messaging with {uid}.", { email: userPropertiesBffResponse.EmailAddress, uid: userPropertiesBffResponse.UniqueId });
    }
}
const chameleonStyleNodeId = "chameleon-design-tokens";
const chameleonDesignTokens = `:root {
    --chmlnDialogBackgroundPrimary: ${themeTokens.color.dialog.background.primary};
    --chmlnTextPrimary: ${themeTokens.color.text.primary};
    --chmlnButtonBackgroundPrimaryDefault: ${themeTokens.color.button.background.primary.default};
    --chmlnButtonBackgroundPrimaryHover: ${themeTokens.color.button.background.primary.hover};
    --chmlnButtonTextPrimary: ${themeTokens.color.button.text.primary};
    --chmlnButtonBorderSecondary: ${themeTokens.color.button.border.secondary};
    --chmlnButtonBackgroundSecondaryDefault: ${themeTokens.color.button.background.secondary.default};
    --chmlnButtonBackgroundSecondaryHover: ${themeTokens.color.button.background.secondary.hover};
    --chmlnButtonTextSecondary: ${themeTokens.color.button.text.secondary};
}`;
function setupDesignTokens() {
    let styleElement = document.querySelector(`#${chameleonStyleNodeId}`);
    if (!styleElement) {
        styleElement = document.createElement("style");
        styleElement.setAttribute("id", chameleonStyleNodeId);
        document.head.appendChild(styleElement);
    }
    styleElement.innerHTML = chameleonDesignTokens;
}
function clearDesignTokens() {
    const styleElement = document.querySelector(`#${chameleonStyleNodeId}`);
    styleElement?.remove();
}
function splitVersionNumber(version: string): (number | null)[] {
    const versionSplit = version.split("-")[0].split(".");
    return versionSplit.map((item) => {
        const parsedItem = parseInt(item);
        return isFinite(parsedItem) ? parsedItem : null;
    });
}
export const exportedForTesting = {
    splitVersionNumber,
};
