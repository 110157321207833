import { Callout } from "@octopusdeploy/design-system-components";
import type { GitRef, ProjectResource } from "@octopusdeploy/octopus-server-client";
import { HasVariablesInGit, Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type { PropsWithChildren } from "react";
import React from "react";
import { GetCommitButton } from "~/areas/projects/components/VersionControl/CommitButton";
import type { CommitMessageWithDetails } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import { lastAccessedGitRef } from "~/areas/projects/context/LastAccessedGitRef";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import { DrawerWithForm } from "~/primitiveComponents/dataDisplay/DrawerWithForm/DrawerWithForm";
interface CloudConnectionsDrawerProps {
    open: boolean;
    actionName: string;
    onSubmit: (commitMessage?: CommitMessageWithDetails) => Promise<void>;
    onLoad: () => Promise<void>;
    cancel: () => void;
    setShowDrawer: (show: boolean) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    okDisabled?: boolean | null;
    project: ProjectResource;
    gitRef?: GitRef;
}
interface CloudConnectionsDrawerState extends DataBaseComponentState {
    commitMessage: CommitMessageWithDetails;
    calloutDismissed?: boolean;
}
export class CloudConnectionsDrawer extends DataBaseComponent<PropsWithChildren<CloudConnectionsDrawerProps>, CloudConnectionsDrawerState> {
    private localStorageKeyForDismissedCallout = "Octopus.Callout.Drawer.CloudConnections.Dismissed";
    constructor(props: PropsWithChildren<CloudConnectionsDrawerProps>) {
        super(props);
        this.state = {
            commitMessage: { details: "", summary: `Update cloud connections` },
            calloutDismissed: localStorage.getItem(this.localStorageKeyForDismissedCallout) === "true",
        };
    }
    submit = () => {
        this.doBusyTask(async () => {
            await this.props.onSubmit();
        });
    };
    componentDidMount() {
        if (this.props.onLoad) {
            this.doBusyTask(async () => {
                await this.props.onLoad();
            });
        }
    }
    getGitPrimaryAction() {
        if (HasVariablesInGit(this.props.project.PersistenceSettings)) {
            /*
             * Non-Git runbooks won't have a gitRef in the route, so in the case where there are variables in Git we fall back to the last accessed gitRef.
             * The gitRef was previously resolved in this way from project context for routes without a gitRef, but we've moved resolving the value of the gitRef
             * to the route instead of project context.
             */
            const gitRef = this.props.gitRef || lastAccessedGitRef.get(this.props.project);
            return (<GetCommitButton hasPermission={{ permission: Permission.ProjectEdit, project: this.props.project.Id }} gitRef={gitRef} persistenceSettings={this.props.project.PersistenceSettings} canCommitToGitRef={true} defaultCommitMessage={`Update cloud connections`} commitMessage={this.state.commitMessage} updateCommitMessage={(commitMessage) => this.setState({ commitMessage })} commitMessageAccessibleName={"Commit message for saving the cloud connections"} commitDetailsAccessibleName={"Commit details for saving the cloud connections"} commitButtonAccessibleName={"Commit changes to the cloud connections"} quickCommitAccessibleName={`Confirm ${this.props.actionName}`} busyLabel={"Committing"} disabled={this.props.okDisabled ?? false} label={"Commit"} onClick={async () => {
                    const savingPromise = this.props.onSubmit(this.state.commitMessage);
                    this.setState({ busy: savingPromise });
                    await savingPromise;
                    return true;
                }} 
            // New branches cannot be made from this area because
            // unsaved changes could be made to the underlying
            // project editor on the original branch.
            disableNewBranchCreation={true}/>);
        }
        return undefined;
    }
    onCloseCallout() {
        localStorage.setItem(this.localStorageKeyForDismissedCallout, true.toString());
        this.setState({ calloutDismissed: true });
    }
    render() {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const spaceId = repository.spaceId!;
        return (<DrawerWithForm busy={this.state.busy} errors={this.errors} onSubmit={this.submit} onClose={this.props.cancel} isOpen={this.props.open} title={this.props.actionName} isPrimaryButtonDisabled={this.props.okDisabled} primaryButtonLabel={"Save"} customPrimaryAction={this.getGitPrimaryAction()} variant="default">
                {!this.state.calloutDismissed ? (<Callout type={"information"} title="Finalise cloud connection" hideTitle={false} canClose={true} onClose={() => this.onCloseCallout()}>
                        <div>
                            After you create a new cloud connection, you can find it in{" "}
                            <InternalLink to={links.infrastructureAccountsPage.generateUrl({ spaceId })} openInSelf={false}>
                                Accounts
                            </InternalLink>
                        </div>
                    </Callout>) : (<></>)}
                {this.props.children}
            </DrawerWithForm>);
    }
    static displayName = "CloudConnectionsDrawer";
}
