import type { SpecifiedGitRef, ProjectResource, GitRef, PersistenceSettings } from "@octopusdeploy/octopus-server-client";
import { HasGitPersistenceSettings, toGitBranch } from "@octopusdeploy/octopus-server-client";
function lastAccessedGitRefStore() {
    const LocalStorageKey = "octoLastAccessedGitRef";
    const buildKey = (projectIdOrSlug: string) => `${LocalStorageKey}-${projectIdOrSlug}`;
    const save = async (project: ProjectResource, gitRef: SpecifiedGitRef): Promise<void> => {
        if (!HasGitPersistenceSettings(project.PersistenceSettings)) {
            throw new Error("Can store GitRef for Git Projects");
        }
        localStorage.setItem(buildKey(project.Id), gitRef);
    };
    const get = (project: ProjectResource): GitRef => {
        if (!HasGitPersistenceSettings(project.PersistenceSettings)) {
            throw new Error("Can only retrieve stored GitRef for Git Projects");
        }
        const key = buildKey(project.Id);
        const storedGitRef = localStorage.getItem(key) ?? toGitBranch(project.PersistenceSettings.DefaultBranch);
        return storedGitRef;
    };
    const getById = (projectId: string, persistenceSettings: PersistenceSettings): GitRef => {
        if (!HasGitPersistenceSettings(persistenceSettings)) {
            throw new Error("Can only retrieve stored GitRef for Git Projects");
        }
        const key = buildKey(projectId);
        const storedGitRef = localStorage.getItem(key) ?? toGitBranch(persistenceSettings.DefaultBranch);
        return storedGitRef;
    };
    return {
        save,
        get,
        getById,
    };
}
const lastAccessedGitRef = lastAccessedGitRefStore();
export { lastAccessedGitRef };
