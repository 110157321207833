import { useMutation, useQuery } from "@octopusdeploy/octopus-react-client";
import type { DeploymentProcessResource, ModifyProcessCommand, ProjectResource } from "@octopusdeploy/octopus-server-client";
import { isDeploymentProcessResource } from "@octopusdeploy/octopus-server-client";
import { useMemo } from "react";
import { toDeploymentProcessIdentifier } from "~/areas/projects/components/Process/types";
export default function useDeploymentProcessState(project: ProjectResource, initialProcess: DeploymentProcessResource, gitRef: string | undefined) {
    const { result: deploymentProcess, refetch: reloadDeploymentProcess } = useQuery((repository) => repository.DeploymentProcesses.get(project.Id, gitRef), [gitRef, project.Id], "Load deployment process", {
        initialResult: initialProcess,
    });
    const { execute: modifyDeploymentProcess } = useMutation({
        name: "Modify deployment process",
        action: (repository, process: ModifyProcessCommand, gitRef: string | undefined) => {
            if (isDeploymentProcessResource(process)) {
                return repository.DeploymentProcesses.modify(process, process.ProjectId, gitRef);
            }
            throw new Error("Attempted to modify a process that isn't a deployment process");
        },
        afterComplete: async () => reloadDeploymentProcess(),
    });
    const deploymentProcessIdentifier = useMemo(() => toDeploymentProcessIdentifier(project), [project]);
    return {
        deploymentProcess,
        deploymentProcessIdentifier,
        reloadDeploymentProcess,
        modifyDeploymentProcess,
    };
}
